<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showDetailButton="showDetailButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="amount" slot-scope="row">
          {{row.item.income}} €
      </template>
      <template slot="vat" slot-scope="row">
          <span v-if="!row.item.is_vat_included">{{ row.item.vat_name }}</span>
      </template>
      <template slot="tot_amount" slot-scope="row">
          {{row.item.tot_amount}} €
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="changeDateRolePerm"
          :title="'Change date' | translate"
          @click.prevent="showModal(row.item, 'showDateChangeModal')"
        >
          <i class="far fa-calendar-alt"></i>
        </b-btn>
      </template>
    </thux-table>
    <change-date-modal
        v-if="showDateChangeModal && selectedItem"
        :title="$t('Change date')"
        :installment='selectedItem'
        @submit="changeDate"
        @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as QUOTATION_INSTALLMENT_TYPES } from '../../quotation/quotation-installment/store'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import ChangeDatelModal from './components/ChangeDatelModal'

export default {
  name: 'QuotationInstallmentTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    'change-date-modal': ChangeDatelModal
  },
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'date_invoice_issue',
          label: this.$t('Invoice issue date'),
          tdClass: 'text-nowrap',
          thClass: 'text-wrap',
          type: 'date',
          sortable: true,
          value: 'date_invoice_issue'
        },
        {
          key: 'expiration_date',
          label: this.$t('Expiration payment date'),
          tdClass: 'text-nowrap',
          thClass: 'text-wrap',
          type: 'date',
          sortable: true,
          value: 'expiration_date'
        },
        {
          key: 'quotation__customer',
          label: this.$t('Customer'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'quotation_customer_name'
        },
        {
          key: 'name',
          label: this.$t('Description'),
          type: 'text',
          thClass: 'text-wrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'quotation',
          label: this.$t('Quotation'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'quotation_ref'
        },
        {
          key: 'amount',
          label: this.$t('Income'),
          type: 'number',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          custom: true
        },
        {
          key: 'vat',
          label: this.$t('VAT'),
          type: 'number',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          custom: true
        },
        {
          key: 'tot_amount',
          label: this.$t('Tot.'),
          type: 'number',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['date_invoice_issue', 'name', 'quotation', 'quotation__customer', 'vat'],
      showDateChangeModal: false,
      selectedItem: undefined,
      showWarningModal: false,
      // Permissions
      changeDateRolePerm: 'quotation_quotationinstallment_update'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.GETTERS.selectAll,
      selectedList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.MUTATIONS.setList,
      setSelectedList:
        QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.MUTATIONS.setSelectedList,
      setSelectAll: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.ACTIONS,
      updateQuotationInstallmentList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.DETAIL.ACTIONS.update
    }),
    showModal (item) {
      this.$set(this, 'showDateChangeModal', true)
      this.$set(this, 'selectedItem', item)
    },
    closeModal () {
      this.$set(this, 'showDateChangeModal', false)
      this.$set(this, 'selectedItem', undefined)
    },
    changeDate (evt) {
      this.updateQuotationInstallmentList(evt).then(
        () => {
          this.$set(this, 'showDateChangeModal', false)
          this.$set(this, 'selectedItem', undefined)
        }
      )
    }
  }
}
</script>
