<template>
  <div :class="!isDetail ? 'wrapper sidebar-collapsed' : ''" v-has-perms="rolePerm">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed panel-with-actions">
        <div class="panel-header">
          <h1 class="page-header mr-2 d-flex align-items-center">
            {{title}}
            <div class="page-header-actions ml-2">
              <b-btn
                variant="outline-primary"
                v-has-perms="downloadExcelRolePerm"
                :title="'Download Excel' | translate"
                @click.prevent="showSendEmailModal = true"
              >
                <i class="fas fa-file-excel"></i>
              </b-btn>
            </div>
          </h1>
          <ol class="breadcrumb pull-right">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard'}">
                {{"Dashboard"}}
              </router-link>
            </li>
            <li class="breadcrumb-item">{{ title | translate }}</li>
          </ol>
        </div>
        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />
        <panel :title="panelTitle" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="body">
              <component-table
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
              />
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters"
      v-show="showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters />
      <send-email-modal
        v-if="showSendEmailModal"
        :show-owner-emails='true'
        :title="$t('Send email with Excel billing plan')"
        @submit="sendExcelBillingPlanEmail"
        @close="showSendEmailModal = false"
      />
    </portal-target>
    <WarningDialog
      v-if="showCreateinvoiceWarning"
      ref-id="modal-delete"
      :no-close-on-backdrop="true"
      confirm-btn-variant="primary"
      :confirm-btn-disabled="false"
      :title="$t('WARNING')"
      :body="warningDialogMessage"
      :button-confirm-text="'OK' | translate"
      @warning-dialog-close="closeModal"
    >
    </WarningDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as QUOTATION_INSTALLMENT_TYPES } from '../../quotation/quotation-installment/store'
import { ROUTES as INVOICE_ROUTES } from '../invoice/router'
import { hasPerm } from '@/components/PermissionHelper'

import WarningDialog from '@/components/WarningDialog'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'
import JobMixin from '@/components/job-button/JobMixin'

import SendEmailModal from '@/components/SendEmailModal'

import QuotationInstallmentTable from './QuotationInstallmentTable'
import QuotationInstallmentCommonFilters from './QuotationInstallmentCommonFilters'

export default {
  name: 'QuotationInstallmentList',
  mixins: [ThuxListMixin, JobMixin],
  components: {
    'component-table': QuotationInstallmentTable,
    'common-filters': QuotationInstallmentCommonFilters,
    WarningDialog,
    SendEmailModal
  },
  data () {
    return {
      title: this.$t('Billing plan'),
      rolePerm: ['quotation_quotationinstallment_list'],
      actionEnablePermission: ['quotation_quotationinstallment_enable'],
      actionDisablePermission: ['quotation_quotationinstallment_disable'],
      searchFields: [
        { key: 'filter__date_invoice_issue__gte', placeholder: this.$t('Invoice issue date greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date_invoice_issue__lte', placeholder: this.$t('Invoice issue date less than equal'), type: 'date', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Description'), type: 'text', col: 6 }
      ],
      actions: [
        { value: null, text: '---' }
      ],
      actionsCustom: [
        { value: 'createInvoice', text: this.$t('Generate invoice'), permissions: 'quotation_quotationinstallment_create_invoices' }
      ].filter(element => {
        return hasPerm(element.permissions)
      }),
      showSendEmailModal: false,
      showCreateinvoiceWarning: false,
      warningDialogMessage: '',
      redirectToInvoice: false,
      createdInvoice: undefined,
      // Permissions
      downloadExcelRolePerm: 'quotation_quotationinstallment_send_billing_plan_job'
    }
  },
  computed: {
    ...mapGetters({
      ...QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.GETTERS
    }),
    showList () {
      return true
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setOrderBy: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.MUTATIONS.setOrderBy,
      setSelectedList: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.MUTATIONS.setSelectedList,
      setSelectAll: QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...QUOTATION_INSTALLMENT_TYPES.GENERIC.quotation.quotationinstallment.LIST.ACTIONS
    }),
    getComponentList () {
      this.setOrderBy('date_invoice_issue.quotation__customer')
      return this.setFilters({ filter__is_billed: 'False', filter__quotation__accepted: 'True' })
    },
    doCustomActions (actionType, idList, counter) {
      this.$set(this, 'redirectToInvoice', false)
      this.$set(this, 'createdInvoice', undefined)
      if (actionType === 'createInvoice') {
        const canCreateInvoice = this.checkCustomer(idList)
        if (canCreateInvoice) {
          this.showCreateinvoiceWarning = false
          this.warningDialogMessage = ''
          console.log((idList))
          this.createInvoices({ row_ids: idList }).then(
            (response) => {
              if (response.success) {
                this.warningDialogMessage = `${this.warningDialogMessage} <p>${response.success}</p>`
                if (response.ids.length > 0) {
                  this.$set(this, 'redirectToInvoice', true)
                  this.$set(this, 'createdInvoice', response.ids[0])
                }
              }
              if (response.errors && response.errors.length > 0) {
                this.warningDialogMessage = `${this.warningDialogMessage} <p>${this.$t('ERRORS')}:</p> <p><ul>`
                response.errors.forEach(element => {
                  this.warningDialogMessage = `${this.warningDialogMessage} <li>${element[1]}</li>`
                })

                this.warningDialogMessage = `${this.warningDialogMessage} </ul></p>`
              }

              this.showCreateinvoiceWarning = true
              this.getList()
            }
          )
        } else {
          this.warningDialogMessage = this.$t('Shares of multiple customers have been selected. Only one customer invoice can be generated at a time.')
          this.showCreateinvoiceWarning = true
        }
      }
    },
    checkCustomer (installmentIdList) {
      const selectedList = this.list.results.filter(qi => installmentIdList.includes(qi.id))
      if (selectedList.length > 1) {
        const customer = selectedList[0].quotation_customer_id
        return selectedList.every(qi => qi.quotation_customer_id === customer)
      }
      return true
    },
    sendExcelBillingPlanEmail (emails) {
      const filters = {}
      Object.keys(this.filters).forEach(key => {
        filters[this.fixKey(key)] = this.filters[key]
        if (key === 'filter__quotation__customer__in') {
          filters[this.fixKey(key)] = this.filters[key].split(',')
        }
      })
      this.$set(this, 'apiCallJobUrl', `${this.apiUrl}/send-billing-plan-job/`)
      this.doJob({ filters: { ...filters }, emails: emails })
    },
    fixKey (key) { return key.replace(/^filter__/, '') },
    afterExecuteJob () {
      this.$set(this, 'showSendEmailModal', false)
    },
    closeModal () {
      this.showCreateinvoiceWarning = false
      if (this.redirectToInvoice && this.createdInvoice) {
        this.$router.push({ name: INVOICE_ROUTES.INVOICE_INVOICE_DETAIL, params: { id: this.createdInvoice } })
      }
    }
  }
}
</script>
