<template>
  <b-modal
    id="modalDateChange"
    ref="modalDateChange"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
    size="lg"
    @hidden="modalDateChangeOnHidden"
  >
    <div slot="modal-title">
      {{ title }}
    </div>
    <b-form-row>
      <form-thux-horizontal-datetime
        :validator="$v.form.date_invoice_issue"
        :label-form="'Invoice issue date' | translate"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2 pl-2"
        type="date"
        :value="invoiceDate"
        :placeholder="$t('Enter the date')"
        @change="dateChange($event, 'date_invoice_issue')"
      />
      <form-thux-horizontal-datetime
        :validator="$v.form.expiration_date"
        :label-form="'Expiration payment date' | translate"
        labelColsSm="0"
        labelColsLg="0"
        class-form="input-row-col mb-2 mt-2 pl-2"
        type="date"
        :value="expirationDate"
        :placeholder="$t('Enter the date')"
        @change="dateChange($event, 'expiration_date')"
      />
    </b-form-row>
    <div slot="modal-footer">
      <!--BUTTON CANCEL-->
      <b-btn class="mr-2" variant="default" @click="modalDateChangeOnHidden">
        {{ 'Cancel' | translate }}
      </b-btn>
      <!--BUTTON SEND-->
      <b-btn variant="primary" :disabled="$v.form.$invalid" @click="onSubmit">
        {{ 'Send' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'

export default {
  name: 'DateChangeModal',
  mixins: [ThuxDetailPanelMixin],
  props: {
    installment: { required: true },
    formEditableUpdate: {}
  },
  data () {
    return {
      form: {},
      expirationDate: undefined,
      invoiceDate: undefined
    }
  },
  mounted () {
    this.openModalChangeDate()
    this.init()
  },
  computed: {
  },
  methods: {
    init () {
      this.$set(this, 'form', this.installment)
      if (this.form.expiration_date) {
        this.$set(this, 'expirationDate', moment(new Date(this.form.expiration_date)))
      }
      if (this.form.date_invoice_issue) {
        this.$set(this, 'invoiceDate', moment(new Date(this.form.date_invoice_issue)))
      }
    },
    // MODAL
    openModalChangeDate () {
      this.$refs.modalDateChange.show()
    },
    modalDateChangeOnHidden () {
      this.$emit('close')
      this.$refs.modalDateChange.hide()
    },
    onSubmit () {
      if (!this.$v.form.$invalid) {
        this.$emit('submit', this.form)
      }
    }
  },
  validations: {
    form: {
      is_billed: {},
      expiration_date: {},
      date_invoice_issue: {}
    }
  }
}
</script>
